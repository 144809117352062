
import lang from "~/mixins/lang";

export default {
  name: "ContentWithImage",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
