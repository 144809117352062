
import lang from "~/mixins/lang.js";
export default {
  name: "OurBlog",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      slickSettings: {
        draggable: true,
        focusOnSelect: false,
        infinite: false,
        dots: false,
        arrow: false,
        autoplay: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.6,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2.3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
        ],
      },
      blogs: this.data?.blogs?.data || [],
    };
  },
};
