import { render, staticRenderFns } from "./TilesNavigation.vue?vue&type=template&id=dbb99138&scoped=true"
import script from "./TilesNavigation.vue?vue&type=script&lang=js"
export * from "./TilesNavigation.vue?vue&type=script&lang=js"
import style0 from "./TilesNavigation.vue?vue&type=style&index=0&id=dbb99138&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbb99138",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TilesNavItem: require('/usr/app/components/tilesNavigation/TilesNavItem.vue').default})
