
import lang from "~/mixins/lang.js";
export default {
  name: "Hero",
  mixins: [lang],
  props: {
    hero: {
      type: Array,
      default: () => {},
    },
  },
  mounted() {
    if (process.browser) {
      const resize = () => (this.small = window.screen.width < 768);
      window.addEventListener("resize", resize);
      resize();
    }
  },
  data() {
    return {
      small: true,
      slickSettings: {
        fade: false,
        draggable: false,
        focusOnSelect: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        dots: false,
        autoplaySpeed: 4000,
        speed: 600,
        rtl: this.dirLang ? true : false,
      },
    };
  },
  computed: {
    isActiveLangEnKW() {
      return this.activeLang === 'en-KW';
    },
  },
  methods: {
    handleVideoClick() {
      if (this.isActiveLangEnKW) {
        const url = 'https://apps.apple.com/us/app/careem-rides-food-more/id592978487?mt=8&pt=592978487&ct=Careem+Website+New+-+20230112';
        window.open(url, '_blank');
      }
    },
  },
};
