
import lang from "~/mixins/lang.js";
export default {
  name: "banner",
  mixins: [lang],
  props: {
    banner: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {};
  },
};
