
import lang from "~/mixins/lang.js";
export default {
  name: "Card",
  mixins: [lang],
  props: {
    cards: {
      type: Object,
      default() {},
    },
    type: {
      type: String,
      default: "",
    },
  },
};
