
import lang from "~/mixins/lang.js";
export default {
  name: "ServiceTiles",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
};
