
import lang from "~/mixins/lang.js";
import CategoryItem from "./CategoryItem.vue";
export default {
  name: "Categories",
  components: { CategoryItem },
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },

  mounted() {
    if (process.browser) {
      const flexibleContainer = document.getElementById("flexibleContainer");
      const resize = () => {
        const small = window.screen.width < 768;
        if (flexibleContainer) {
          flexibleContainer.style.width = `${
            this.categories.length * (small ? 267 : 329) - 14
          }px`;
        }
      };
      window.addEventListener("resize", resize);
      resize();
    }
  },
  data() {
    return {
      categories: this.data?.product_groups?.data ?? [],
      heading: this.data?.heading,
      variant: this.data?.variant,
      small: false,
    };
  },
};
