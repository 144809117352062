
import lang from "~/mixins/lang.js";

export default {
  name: "CategoryItem",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
    variant: {
      type: String,
    },
  },
  data() {
    return {
      url: this.data?.products?.data?.[0]?.attributes?.slug ?? "",
    };
  },
  methods: {
    handleCompClick() {
      if (this.variant === "linkGroup") return;
      this.$router.push({
        path: `/${this.activeLang === "" ? "en-AE" : this.activeLang}/${
          this.url
        }/`,
      });
    },
    // function to check if the given string is an absolute path or external url
    isExternalUrl(url = "") {
      if (!url) return false;
      return url.startsWith("http://") || url.startsWith("https://");
    },
  },
};
