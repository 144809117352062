
import lang from "~/mixins/lang.js";
import BannerV2 from "../bannerV2/bannerV2.vue";

export default {
  name: "BannerCarousel",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      slickSettings: {
        draggable: true,
        focusOnSelect: true,
        infinite: true,
        dots: false,
        arrow: false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
      },
    };
  },
  components: { BannerV2 },
};
