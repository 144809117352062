
import lang from "@/mixins/lang.js";
import seo from "@/mixins/seo.js";
import banners from "@/mixins/banners.js";
import ContentWithImage from "~/components/contentWithImage/contentWithImage.vue";
import BannerV2 from "~/components/bannerV2/bannerV2.vue";
import VideoEmbed from "~/components/videoEmbed/VideoEmbed.vue";
import BannerCarousel from "~/components/bannerCarousel/BannerCarousel.vue";
import ServiceTiles from "../components/serviceTiles/ServiceTiles.vue";

import allHomepages from "~/all-homepages.json";
import QrModal from "~/components/qr-modal/QrModal.vue";

const qs = require("qs");

export default {
  components: {
    ContentWithImage,
    BannerV2,
    VideoEmbed,
    BannerCarousel,
    ServiceTiles,
    QrModal,
  },
  layout: "default",
  mixins: [lang, seo, banners],
  data() {
    return {
      page: {},
      seo: {
        metaTitle: "",
        metaDescription: "",
        keywords: "",
        canonicalURL: "",
        shareImage: {},
      },
      query: qs.stringify(
        {
          populate: [
            "seo.metaImage",
            "hero.button",
            "hero.image",
            "hero.image_mobile",
            "hero.image_tablet",
            "details.image",
            "details.headerImage",
            "details.button",
            "details.extra_buttons",
            "details.products.productImage",
            "details.cards.image",
            "details.banners.image",
            "details.banners.headerImage",
            "details.blogs.image",
            "details.product_groups.products",
          ],
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      ),
    };
  },
  async fetch() {
    const computedLocale =
      this.activeLang && this.activeLang.length > 0
        ? this.activeLang
        : this.browserLang && this.browserLang.length > 0
        ? this.browserLang
        : "en-AE";

    const foundData = allHomepages[computedLocale];

    this.page = foundData;
    this.seo = this.seo ? this.page.seo : this.page;

    //-----------making call only for dev environment
    if (process.env.ENV === "dev") {
      const { data: respData } = await this.$axios.get(
        `/home?locale=${computedLocale}&${this.query}`
      );
      this.page = respData?.data?.attributes;
      this.page.id = respData?.data?.id;
      this.components = this.page.details;
      this.seo = this.seo ? this.page.seo : this.page;
    }
    //========================
  },
};
