
import lang from "~/mixins/lang.js";
export default {
  name: "Product",
  mixins: [lang],
  props: {
    product: {
      type: Object,
      default() {},
    },
    type: {
      type: Number,
      default: 3,
    },
    showDescriptions: {
      type: Boolean,
      default: false,
    },
  },
};
