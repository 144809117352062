const scriptData = () => ([
  {
    src: '/scripts/gtm.js'
  },
]);

export default {
  head() {
    const defaultTitle = 'Careem';
    const defaultLang = 'en-AE';
    const defaultDir = 'ltr';
    const defaultImage = './Careem_og-image.jpg';
    const defaultKeywords = 'Careem';

    const htmlAttrs = {
      lang: this.activeLang?.length > 0 ? this.activeLang : defaultLang,
      dir: this.dirLang ? 'rtl' : defaultDir
    };

    const meta = [
      { hid: 'description', name: 'description', content: this.seo?.metaDescription || defaultTitle },
      // OG Metas
      { hid: 'og:title', property: 'og:title', content: this.seo?.metaTitle || defaultTitle },
      { hid: 'og:description', property: 'og:description', content: this.seo?.metaDescription || defaultTitle },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'og:site_name', name: 'site_name', property: 'og:site_name', content: defaultTitle },
      { hid: 'og:author', name: 'author', property: 'og:author', content: defaultTitle },
      { hid: 'og:locale', property: 'og:locale', content: 'en_AE' },
      { hid: 'og:image', property: 'og:image', content: this.seo?.metaImage?.data?.attributes?.url || defaultImage },
      { hid: 'og:image:secure_url', property: 'og:image:secure_url', content: this.seo?.metaImage?.data?.attributes?.url || defaultImage },
      { hid: 'og:url', property: 'og:url', content: this.seo?.canonicalURL || this.$route.path },
      { hid: 'og:keywords', name: 'keywords', property: 'og:keywords', content: this.seo?.keywords || defaultKeywords },
      { hid: 'twitter:image', name: 'twitter:image', property: 'twitter:image', content: this.seo?.metaImage?.data?.attributes?.url || defaultImage },
      { hid: 'twitter:site', name: 'site', property: 'twitter:site', content: '@Careem' },
      { hid: 'twitter:card', name: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
      { hid: 'twitter:creator', name: 'twitter:creator', property: 'twitter:creator', content: '@Careem' },
      { hid: 'twitter:title', name: 'twitter:title', property: 'twitter:title', content: this.seo?.metaTitle || defaultTitle },
      { hid: 'twitter:description', name: 'twitter:description', property: 'twitter:description', content: this.seo?.metaDescription || defaultTitle },
      { hid: 'twitter:image:alt', name: 'twitter:image:alt', property: 'twitter:image:alt', content: this.seo?.metaImage?.data?.attributes?.alternativeText || 'Careem Og Image' },
    ];

    return this.seo ? {
      title: this.seo?.metaTitle || defaultTitle,
      htmlAttrs,
      meta,
      script: scriptData(),
    } : {
      title: defaultTitle,
      htmlAttrs,
      script: scriptData(),
    }
  }
};