
import lang from "~/mixins/lang.js";
import device from "~/mixins/device.js";
export default {
  name: "Group",
  mixins: [lang, device],
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      productType: 3,
      showMore: false,
      slickSettings: {
        draggable: false,
        focusOnSelect: false,
        infinite: true,
        speed: 250,
        dots: true,
        arrow: true,
        rtl: this.dirLang ? true : false,
        responsive: [
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: false,
              arrow: false,
            },
          },
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 4.5,
              slidesToScroll: 1,
              dots: false,
              arrow: false,
            },
          },
        ],
      },
    };
  },
  created() {
    this.productTypeFunc();
  },
  methods: {
    productTypeFunc() {
      if (this.content?.type === "large") {
        this.productType = 3;
      } else {
        this.productType = 4;
      }
    },
    handleSeeMoreClick(e) {
      const el = this.$el.querySelector(".sub-container");
      if (el) {
        el.style.height = this.showMore ? "24rem" : `${el.scrollHeight}px`;
        setTimeout(() => {
          this.showMore = !this.showMore;
        }, 100);
      }
    },
  },
};
