import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4a8db91d"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroSlider: require('/usr/app/components/hero/HeroSlider.vue').default,HeroSliderLegacy: require('/usr/app/components/hero/HeroSliderLegacy.vue').default,Categories: require('/usr/app/components/categories/Categories.vue').default,TilesNavigation: require('/usr/app/components/tilesNavigation/TilesNavigation.vue').default,ServiceTiles: require('/usr/app/components/serviceTiles/ServiceTiles.vue').default,BannerV2: require('/usr/app/components/bannerV2/bannerV2.vue').default,BannerCarousel: require('/usr/app/components/bannerCarousel/BannerCarousel.vue').default,ContentWithImage: require('/usr/app/components/contentWithImage/contentWithImage.vue').default,ProductGroup: require('/usr/app/components/product/Group.vue').default,SectionCard: require('/usr/app/components/section/Card.vue').default,Banner: require('/usr/app/components/banner/Banner.vue').default,VideoEmbed: require('/usr/app/components/videoEmbed/VideoEmbed.vue').default,QrModal: require('/usr/app/components/qr-modal/QrModal.vue').default,OurBlog: require('/usr/app/components/ourBlog/OurBlog.vue').default})
