
import lang from "~/mixins/lang.js";
export default {
  name: "HeroSliderLegacy",
  mixins: [lang],
  props: {
    hero: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      slickSettings: {
        fade: true,
        draggable: false,
        focusOnSelect: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 250,
        arrows: false,
        dots: false,
        rtl: this.dirLang ? true : false,
      },
    };
  },
};
