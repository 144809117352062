
import lang from "~/mixins/lang.js";
import TilesNavItem from "./TilesNavItem.vue";
export default {
  name: "TilesNavigation",
  components: { TilesNavItem },
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },

  mounted() {
    if (process.browser) {
      const flexibleContainer = document.getElementById("flexibleContainer");
      const resize = () => {
        const small = window.screen.width < 768;
        if (flexibleContainer) {
          flexibleContainer.style.width = `${
            this.products.length * (small ? 267 : 329) - 14
          }px`;
        }
      };
      window.addEventListener("resize", resize);
      resize();
    }
  },
  data() {
    return {
      products: this.data?.products?.data ?? [],
      heading: this.data?.heading,
      small: false,
    };
  },
};
