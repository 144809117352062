
import lang from "@/mixins/lang.js";
export default {
  name: "VideoEmbed",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const { videoLink } = this?.data ?? {};
    return {
      embedLink: !!videoLink ? this.createEmbedLink(videoLink) : "",
    };
  },
  methods: {
    createEmbedLink(link = "") {
      const { autoplay, controls } = this?.data ?? {};
      const url = new URL(link);
      const videoId = url.searchParams.get("v");
      return `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=${this.boolean(
        autoplay
      )}&showinfo=0&controls=${this.boolean(controls)}`;
    },
    boolean: (val) => (Boolean(val) ? 1 : 0),
    hyphenate(txt = "") {
      txt = txt?.toLowerCase();
      return txt.split(" ").join("-");
    },
  },
};
