
import lang from "~/mixins/lang.js";
import Markdown from "../markdown/Markdown.vue";

export default {
  name: "BannerV2",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
    carousel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: { Markdown },
};
