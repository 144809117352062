
import lang from "~/mixins/lang.js";

import { debounce } from "~/utils/debounce";

export default {
  name: "QrModal",
  mixins: [lang],
  props: {
    data: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.checkStatus();
  },
  watch: {
    $route(to) {
      this.checkStatus(to);
    },
  },
  methods: {
    checkStatus(route = this.$route) {
      const { trigId } = route.query || {};
      if (trigId) {
        this.handleQrModal(Boolean(trigId === this.data?.triggerId + "/"));
      }
    },
    handleQrModal(status = !this.show) {
      const desktopView = window?.innerWidth >= 768;
      if (!desktopView && status && process.browser) {
        window?.location.replace(this.data?.url);
        const query = Object.assign({}, this.$route.query);
        delete query?.trigId;
        this.$router.replace({ query });
        return;
      }
      this.show = status;
      if (status === false && this.$route?.query?.trigId) {
        const query = Object.assign({}, this.$route.query);
        if (query?.trigId === this.data?.triggerId + "/") {
          delete query?.trigId;
          this.$router.replace({ query });
        }
      }
    },
  },
};
